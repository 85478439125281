exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-culture-tsx": () => import("./../../../src/pages/culture.tsx" /* webpackChunkName: "component---src-pages-culture-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notice-tsx": () => import("./../../../src/pages/notice.tsx" /* webpackChunkName: "component---src-pages-notice-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-templates-notice-template-tsx": () => import("./../../../src/templates/notice_template.tsx" /* webpackChunkName: "component---src-templates-notice-template-tsx" */)
}

